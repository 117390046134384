import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import AboutSection from "../components/AboutSection";
import BlogSection from "../components/BlogSection";
import BookingSection from "../components/BookingSection";
import ClientSection from "../components/ClientSection";
import CTASection from "../components/CTASection";
import GiftCardsSection from "../components/GiftCardsSection";
import HeroSection from "../components/Hero";
import Layout from "../components/Layout";
import PlansSection from "../components/PlansSection";
import Seo from "../components/SEO";
import ServicesSection from "../components/ServicesSection";
import Testimonial from "../components/Testimonial";
import PopUp from "../components/PopUp";
import { useState } from "react";

const META_DATA = {
  title: "Wellness & Recovery Studio | Washington DC | HighKey Balance", 
  description: `Recover, relax and recharge at Washington D.C.'s premier all-in-one wellness and recovery studio, plus whole body cryotherapy center. Call or book today!`,
};

const Home = () => {
  const { allSanityServices } = useStaticQuery(graphql`
    query GetServicesCardSectionData {
      allSanityServices {
        nodes {
          title
          slug {
            current
          }
          cardDescription
          featuredImageMobile {
            asset {
              url
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  const services = allSanityServices.nodes;

  const [toggle, setToggle] = useState();

  const toggleFunc = (flag) => {
    setToggle(flag);
  };

  return (
    <>
      <Layout>
        <Seo {...META_DATA} />
        <HeroSection />
        <CTASection />
        <ClientSection />
        <AboutSection />
        <ServicesSection services={services} />
        <Testimonial />
        <PlansSection toggleFunc={toggleFunc}/>
        <GiftCardsSection />
        <BlogSection />
        <BookingSection />
      </Layout>
    </>
  );
};

export default Home;
